import {useEffect, useState, useCallback} from 'react';

import {OneTrustCookieCategory} from '../enums/one-trust-cookie-categories';

interface IUseCitrusSessionIdProps {
    withConsentCheck: boolean;
}

const DEFAULT_ARGS = {withConsentCheck: true};

export const SESSION_COOKIE_NAME = 'dtm_token';
export const CONSENT_UPDATE_EVENT = 'OneTrustGroupsUpdated';

export const useCitrusSessionId = (props: IUseCitrusSessionIdProps = DEFAULT_ARGS): string | null => {
    const [sessionId, setSessionId] = useState<string | null>(null);

    const {withConsentCheck} = props;

    const parseCookie = useCallback(() => {
        const cookieIdent = `${SESSION_COOKIE_NAME}=`;
        const cookie = document.cookie.split(';').find((value) => value.includes(cookieIdent));

        if (cookie) {
            const citrusSessionId = cookie.replace(cookieIdent, '').trim();

            setSessionId(citrusSessionId);
        }
    }, []);

    useEffect(() => {
        const {OptanonActiveGroups} = window;

        const userConsentsToPerformanceCookies = withConsentCheck
            ? !OptanonActiveGroups || OptanonActiveGroups.includes(OneTrustCookieCategory.Performance)
            : true;

        if (userConsentsToPerformanceCookies) {
            parseCookie();
        }
    }, [withConsentCheck, parseCookie]);

    useEffect(() => {
        const handleConsentUpdate = (event: CustomEvent<OneTrustCookieCategory[]>) => {
            if (!event.detail.includes(OneTrustCookieCategory.Performance)) {
                setSessionId(null);
            } else {
                parseCookie();
            }
        };

        window.addEventListener(CONSENT_UPDATE_EVENT, handleConsentUpdate);

        return () => window.removeEventListener(CONSENT_UPDATE_EVENT, handleConsentUpdate);
    }, [parseCookie]);

    return sessionId;
};
