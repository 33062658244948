import getConfig from 'next/config';

import {
    GetProductsAndFiltersFromElasticsearch_searchProductsResultV2_searchFilters as SearchFiltersType,
    GetProductsAndFiltersFromElasticsearch_searchProductsResultV2_searchProducts as SearchProductsType,
    GetProductsAndFiltersFromElasticsearch_searchProductsResultV2_pageInfo as PageInfoType,
    GetProductsAndFiltersFromElasticsearch_searchProductsResultV2 as SearchResultsType,
    GetProductsAndFiltersFromElasticsearch_searchProductsResultV2_searchProducts as SearchProducts
} from 'autogen/GetProductsAndFiltersFromElasticsearch';
import {
    GetProductsAndFiltersFromCategory_categoryProductsResult as CategoryResultsType,
    GetProductsAndFiltersFromCategory_categoryProductsResult_searchProducts as CategorySearchProducts
} from 'autogen/GetProductsAndFiltersFromCategory';
import {SearchSortDirection} from 'autogen/globalTypes';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';

export const sortOptions = [
    {
        label: 'Relevance',
        value: SearchSortDirection.RELEVANCE
    },
    {
        label: 'Price: Low to High',
        value: SearchSortDirection.PRICE_ASCENDING
    },
    {
        label: 'Price: High to Low',
        value: SearchSortDirection.PRICE_DESCENDING
    },
    {
        label: 'A - Z',
        value: SearchSortDirection.NAME_A_To_Z
    },
    {
        label: 'Z - A',
        value: SearchSortDirection.NAME_Z_To_A
    }
];

export const filterSearchFilters = (
    searchResults: CategoryResultsType | SearchResultsType | null
): SearchFiltersType[] => searchResults?.searchFilters ?? [];

const mapSearchResultsToSearchProducts = (searchProduct: CategorySearchProducts | SearchProducts) => ({
    sponsored: null,
    ...searchProduct
});

export const filterSearchProducts = (
    searchResults: CategoryResultsType | SearchResultsType | null
): SearchProductsType[] =>
    searchResults?.searchProducts ? searchResults.searchProducts.map(mapSearchResultsToSearchProducts) : [];

export const filterSearchPageInfo = (
    searchResults: CategoryResultsType | SearchResultsType | null
): PageInfoType | undefined => searchResults?.pageInfo ?? undefined;

export const filterSearchCorrectedQuery = (
    searchResults: CategoryResultsType | SearchResultsType | null
): string | undefined => searchResults?.correctedQuery ?? undefined;

export const getRenderedProductRange = (pageInfo: PageInfoType): string => {
    const {pageSize, totalCount, page} = pageInfo;

    if (totalCount === 0) {
        return '0 Results';
    }

    const firstNumber = (page - 1) * pageSize + 1;
    const secondNumber = (page - 1) * pageSize + pageSize;

    return `${firstNumber} - ${secondNumber > totalCount ? totalCount : secondNumber} of ${totalCount} Results`;
};

const lowercaseAndRemovePunct = (str) => str.toLowerCase().replace(/\W/gu, '');

export const filterOptionsByGroupSearch = (options, currentGroupSearch) =>
    options.filter((option) => {
        const cleanOptionName = lowercaseAndRemovePunct(option.name);
        const cleanCurrentGroupSearch = lowercaseAndRemovePunct(currentGroupSearch);

        return cleanOptionName.includes(cleanCurrentGroupSearch);
    });

export const getAuthType = (customerData) => (customerData.customerId ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE);

export const getNumberOfSponsoredProducts = () => getConfig().publicRuntimeConfig.numberOfSponsoredProducts;

export const productsExist = (searchResults) => Boolean(searchResults?.searchProductsResultV2?.searchProducts?.length);
