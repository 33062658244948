import {gql} from '@apollo/client';

export const bannerFragment = gql`
    fragment bannerFragment on banner {
        bannerId
        mainHref
        imageUri
        isActive
        type
        title
    }
`;

export const getSearchBanners = gql`
    query getSearchBanners {
        banners(where: {isActive: true, type: SEARCH}) {
            ...bannerFragment
        }
    }
    ${bannerFragment}
`;

export const getFilterPanelSlot = gql`
    query getFilterPanelSlot(
        $searchTerm: String
        $storeId: Int
        $customerUuid: String
        $cartId: Int
        $sessionId: String
    ) {
        allCmsBrowseShopAOWebSearchPage(limit: 1, sort: {publishedAt: DESC}, where: {_: {is_draft: false}}) {
            filterPanelBannerImageSlot {
                placement
                tacticId
                contentType
                desktopSlot {
                    slotId
                }
                bannerImages(
                    searchTerm: $searchTerm
                    storeId: $storeId
                    customerUuid: $customerUuid
                    cartId: $cartId
                    sessionId: $sessionId
                ) {
                    altText
                    bannerImageId
                    bidRanking
                    imageUrl
                    link
                    productIds
                    slotId
                }
            }
        }
    }
`;

export const getCheckoutSlot = gql`
    query getCheckoutSlot($storeId: Int, $customerUuid: String, $cartId: Int, $sessionId: String) {
        allCmsAOWebCheckoutPage(limit: 1, sort: {publishedAt: DESC}, where: {_: {is_draft: false}}) {
            checkoutPanelBannerImageSlot {
                placement
                tacticId
                contentType
                desktopSlot {
                    slotId
                }
                bannerImages(storeId: $storeId, customerUuid: $customerUuid, cartId: $cartId, sessionId: $sessionId) {
                    altText
                    bannerImageId
                    bidRanking
                    imageUrl
                    link
                    productIds
                    slotId
                }
            }
        }
    }
`;

export const getProductDetailsSlot = gql`
    query getProductDetailsSlot(
        $searchTerm: String
        $storeId: Int
        $customerUuid: String
        $cartId: Int
        $sessionId: String
    ) {
        allCmsAOWebProductDetailsPage(limit: 1, sort: {publishedAt: DESC}, where: {_: {is_draft: false}}) {
            bannerImageSlot {
                placement
                contentType
                desktopSlot {
                    slotId
                }
                mobileSlot {
                    slotId
                }
                bannerImages(
                    searchTerm: $searchTerm
                    storeId: $storeId
                    customerUuid: $customerUuid
                    cartId: $cartId
                    sessionId: $sessionId
                ) {
                    altText
                    bannerImageId
                    bidRanking
                    imageUrl
                    link
                    productIds
                    slotId
                }
            }
        }
    }
`;

export const getBannersQuery = gql`
    query getBanners(
        $placement: String!
        $slotIds: [String!]!
        $searchTerm: String
        $storeId: Int
        $customerUuid: String
        $cartId: Int
        $sessionId: String
    ) {
        bannerImages(
            placement: $placement
            slotIds: $slotIds
            searchTerm: $searchTerm
            storeId: $storeId
            customerUuid: $customerUuid
            cartId: $cartId
            sessionId: $sessionId
        ) {
            altText
            bannerImageId
            bidRanking
            imageUrl
            link
            productIds
            slotId
        }
    }
`;
